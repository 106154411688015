import { useAppDispatch, useAppSelector } from "../store";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getLivePosts, Post } from "../model/posts";
import { editPost, setLastError } from "../store/session";
import { TableLabel } from "../components/tables";
import EditIcon from "@mui/icons-material/Edit";
import {useLazyQuery} from "@apollo/client";
import {GET_LIVE_POSTS} from "../Queries/PostQueries";

const PAGE_SIZE = 200;

export const LivePosts = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [posts, setPosts] = useState<Post[]>([]);
  const [getLivePosts, { data }] = useLazyQuery(GET_LIVE_POSTS);

  const loadLivePosts = useCallback(async () => {
    if (user) {
      dispatch(setLastError());
      {
        const { data, error } = await getLivePosts({
          variables: {
            offset: 0,
            size: PAGE_SIZE,
          },
        });
        if (error) {
          dispatch(setLastError(error?.message));
        }
        setPosts(data?.posts || []);
      }
    }
  }, [dispatch, user, getLivePosts]);

  useEffect(() => {
    loadLivePosts().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadLivePosts]);

  const onEditPost = useCallback(
    (post: Post, edit: 'content' | 'interests') => {
      dispatch(
        editPost({
          back: 'live',
          edit,
          post:{
            id: post.id,
            source: post.source,
            description: post.description,
            content: post.content,
            title: post.title,
            tags: post.tags,
            connectorId: post.connectorId,
            gifId: post.gifId,
            language: post.language
        }
      })
      );
    },
    [dispatch]
  );

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} alignItems="center">
        <Typography variant="h4">Live Posts</Typography>
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableLabel label="Title" />
              {/* <TableLabel label="Topic" />
              <TableLabel label="Tags" /> */}
              <TableLabel label="Interests" />
              <TableLabel label="Source" />
              <TableLabel label="Gif" />
              {/* <TableLabel label="Ad (Video/Lyrics)" /> */}
              <TableLabel label="Actions" />
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.map((post) => (
              <TableRow
                key={post.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Typography>{post.title}</Typography>
                  <Typography fontSize={"0.9em"}>{post.description}</Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography>{pendingPost.topic}</Typography>
                </TableCell>
                <TableCell /> */}
                <TableCell>{post.interests}</TableCell>
                <TableCell>
                  <Typography>
                    <Link
                      fontSize={"0.8em"}
                      href={post.source_link}
                      target={"_blank"}
                    >
                      {post.source_link}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                  <img
                  alt="gif"
                    about="gif"
                    src={post?.gif?.url}
                    style={{
                      width: 100,
                      height: 100,
                    }}
                  />
                </TableCell>
                {/* <TableCell>
                </TableCell> */}
                <TableCell>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={() => onEditPost(post, "content")}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onEditPost(post, "interests")}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Interests
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
