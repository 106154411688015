import { useAppDispatch, useAppSelector } from "../store";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getPendingPosts, Post } from "../model/posts";
import { editPost, setLastError } from "../store/session";
import { TableLabel } from "../components/tables";
import EditIcon from "@mui/icons-material/Edit";
import {useLazyQuery} from "@apollo/client";
import {GET_PENDING_POSTS} from "../Queries/PostQueries";

const PAGE_SIZE = 100;

export const PendingPosts = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [pendingPosts, setPendingPosts] = useState<Post[]>([]);

  const [getPendingPosts, { data }] = useLazyQuery(GET_PENDING_POSTS);

  const loadPendingPosts = useCallback(async () => {
    if (user) {
      dispatch(setLastError());

      {
        const { data, error } = await getPendingPosts({
          variables: {
            offset: 0,
            size: PAGE_SIZE,
          },
        });
        if (error) {
          dispatch(setLastError(error?.message));
        }
        setPendingPosts(data?.posts || []);
      }
    }
  }, [dispatch, getPendingPosts, user]);

  useEffect(() => {
    loadPendingPosts().catch((err) => dispatch(setLastError(err)));
  }, [dispatch, loadPendingPosts]);

  const onEditPost = useCallback(
    (post: Post, edit: 'content' | 'interests') => {
      dispatch(
        editPost({
          back: 'pending',
          edit,
          post:{
            id: post.id,
            source: post.source,
            description: post.description,
            content: post.content,
            title: post.title,
            tags: post.tags,
            connectorId: post.connectorId,
            gifId: post.gifId,
            language: post.language
        }
      })
      );
    },
    [dispatch]
  );

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} alignItems="center">
        <Typography variant="h4">Pending Posts</Typography>
      </Stack>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableLabel label="Title" />
              {/* <TableLabel label="Topic" />
              <TableLabel label="Tags" /> */}
              <TableLabel label="Interests" />
              <TableLabel label="Source" />
              <TableLabel label="Gif" />
              {/* <TableLabel label="Ad (Video/Lyrics)" /> */}
              <TableLabel label="Actions"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingPosts.map((pendingPost) => (
              <TableRow
                key={pendingPost.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Typography>{pendingPost.title}</Typography>
                  <Typography fontSize={"0.9em"}>
                    {pendingPost.description}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography>{pendingPost.topic}</Typography>
                </TableCell>
                <TableCell /> */}
                <TableCell>
                  {pendingPost.interests}
                </TableCell>

                <TableCell>
                  <Typography>
                    <Link
                      fontSize={"0.8em"}
                      href={pendingPost.source}
                      target={"_blank"}
                    >
                      {pendingPost.source}
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>
                {pendingPost.gifId}
                </TableCell>
                {/* <TableCell>
                </TableCell> */}
                <TableCell>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Button
                      onClick={() => onEditPost(pendingPost, 'content')}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => onEditPost(pendingPost, 'interests')}
                      color="primary"
                      variant="contained"
                      startIcon={<EditIcon />}
                    >
                      Interests
                    </Button>
                    </Stack>
                    </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
