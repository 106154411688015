import {
  Badge,
  BadgeProps,
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Grid2 from "@mui/material/Unstable_Grid2"; // Grid version 2
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { COLORS } from "../theme";
import {
  editInterest,
  InterestType,
  setInterestSearch,
  setLanguage,
  setLastError,
} from "../store/session";
import { useAppDispatch, useAppSelector } from "../store";
import { MainButton } from "../components/buttons";
import { GridItem1 } from "../components/grids";
import { findInterests, findSubInterests, findSubSubInterests, getInterests, Interest } from "../model/interests";
import { Language, LanguageKeys } from "../model/languages";
import { LanguageMenu } from "../components/LanguageMenu";
import {useQuery} from "@apollo/client";
import {GET_MAIN_INTERESTS, GET_SUB_INTERESTS, GET_SUB_SUB_INTERESTS} from "../Queries/InterestsQueries";
import {formatInterests} from "../utils";

export const Interests = () => {
  const user = useAppSelector((state) => state.session.user);
  const interestSearch = useAppSelector(
    (state) => state.session.interestSearch
  );
  const language = useAppSelector(
    (state) => state.session.language || Language.EN
  );
  const dispatch = useAppDispatch();
  const [interests, setInterests] = useState<Interest[]>([]);
  const [subInterests, setSubInterests] = useState<Interest[]>([]);
  const [subSubInterests, setSubSubInterests] = useState<Interest[]>([]);

  const handleLanguage = useCallback(
    (lang: Language) => {
      dispatch(setLanguage(lang));
    },
    [dispatch]
  );

  const handleAddNew = useCallback(() => {
    dispatch(editInterest({type: 'main'}));
  }, [dispatch]);

  const handleChangeSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setInterests([]);
      setSubInterests([]);
      setSubSubInterests([]);
      dispatch(setInterestSearch(event.target.value));
    },
    [dispatch]
  );
  
  const interestLanguage = language.toLocaleLowerCase() as LanguageKeys;


  const {loading} = useQuery(GET_MAIN_INTERESTS, {
    onCompleted: (data) => {
      const languageMappedInterests = data?.interests.map(formatInterests);
      setInterests(languageMappedInterests);
    },
    fetchPolicy: "no-cache"
  });
  const {loading:subInterestsLoading} = useQuery(GET_SUB_INTERESTS, {
    onCompleted: (data) => {
      const languageMappedInterests = data?.interests.map(formatInterests);
      setSubInterests(languageMappedInterests);
    },
    fetchPolicy: "no-cache"
  });
  const {loading: subSubInterestsLoading} = useQuery(GET_SUB_SUB_INTERESTS, {
    onCompleted: (data) => {
      const languageMappedInterests = data?.interests.map(formatInterests);
      setSubSubInterests(languageMappedInterests);
    },
    fetchPolicy: "no-cache"
  });



  const isLoading = loading || subInterestsLoading || subSubInterestsLoading;

  
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography variant="h4">Interests</Typography>
        <LanguageMenu language={language} onChange={handleLanguage} />
        <Box flexGrow={1}>
          <TextField
            fullWidth
            label="Search"
            id="search"
            value={interestSearch}
            style={{ backgroundColor: COLORS.panelBackground }}
            onChange={handleChangeSearch}
          />
        </Box>
      </Stack>
      <Stack spacing={2} direction={"row"}>
        <MainButton onClick={handleAddNew}>Add new</MainButton>
      </Stack>
      {(isLoading) && <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>}
      {!isLoading  &&
      <Stack spacing={1}>
        {interests.length > 0 && (
          <InterestsPanel
            title="Main Interests"
            total={interests?.length}
            interests={interests}
            language={interestLanguage}
          />
        )}
        {subInterests.length > 0 && (
          <ChildInterestsPanel
            title="Sub Interests"
            total={subInterests?.length}
            type='sub'
            interests={subInterests}
            language={interestLanguage}
          />
        )}
        {subSubInterests.length > 0 && (
          <ChildInterestsPanel
            title="Sub-Sub Interests"
            total={subSubInterests?.length}
            type='subsub'
            interests={subSubInterests}
            language={interestLanguage}
          />
        )}
      </Stack>}
    </Stack>
  );
};

const InterestsPanel = ({
  title,
  total,
  interests,
  language,
}: {
  title: string;
  total: number;
  interests: Interest[];
  language: LanguageKeys;
}) => {
  return (
    <Paper>
      <Stack padding={2}>
        <InterestPanelTitle title={title} total={total} />
        <Grid2
          container
          columns={{ xs: 1, md: 3, lg: 5, xl: 7 }}
          spacing={{ xs: 1, md: 1, lg: 1, xl: 1 }}
        >
          {interests.map((interest) => (
            <InterestButton
              key={interest.id}
              interest={interest}
              language={language}
            />
          ))}
        </Grid2>
      </Stack>
    </Paper>
  );
};

export const ChildInterestsPanel = ({
  title,
  total,
  interests,
  type,
  language,
}: {
  title: string;
  total?: number;
  interests: Interest[];
  type: InterestType;
  language: LanguageKeys;
}) => {
  return (
    <Paper>
      <Stack padding={2}>
        <InterestPanelTitle title={title} total={total} />
        <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
          {interests.map((interest) => (
            <ChildInterestButton
              key={interest.id}
              interest={interest}
              type={type}
              language={language}
            />
          ))}
        </Box>
      </Stack>
    </Paper>
  );
};

const InterestPanelTitle = ({
  title,
  total,
}: {
  title: string;
  total?: number;
}) => {
  return (
    <Stack direction={"row"} alignItems="center" paddingBottom={2}>
      <Typography variant="h5" paddingRight={2}>
        {title}
      </Typography>
      {total && (
        <Typography
          borderRadius={2}
          paddingLeft={1}
          paddingRight={1}
          color={COLORS.mainButton}
          style={{ backgroundColor: COLORS.countBackground }}
        >
          {total}
        </Typography>
      )}
    </Stack>
  );
};

const InterestButton = ({
  interest,
  language,
}: {
  interest: Interest;
  language: LanguageKeys;
}) => {
  const dispatch = useAppDispatch();
  const handleClick = useCallback(() => {
    dispatch(editInterest({
      type: 'main',
      id: interest.id
    }));
  }, [dispatch, interest.id]);

  return (
    <GridItem1 key={interest.id}>
      <Button
        fullWidth
        style={{
          backgroundColor: interest.color,
          color: "black",
          textTransform: "capitalize",
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: 12,
        }}
        variant="contained"
        onClick={handleClick}
      >
        <Box flexGrow={1} display="flex" alignItems={"center"}>
          <Box
            flexGrow={1}
            flexDirection="row"
            display="flex"
            alignItems={"center"}
          >
            {interest.names[language]}
          </Box>
          <SubStyledBadge badgeContent={interest.users} max={100000}>
            <ArrowForwardIosIcon />
          </SubStyledBadge>
        </Box>
      </Button>
    </GridItem1>
  );
};

const ChildInterestButton = ({
  interest,
  type,
  language,
}: {
  interest: Interest;
  type: InterestType;
  language: LanguageKeys;
}) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(editInterest({
      type,
      id: interest.id,
      parentId: interest.parentId
    }));
  }, [dispatch, interest.id, interest.parentId, type]);

  return (
    <GridItem1 key={interest.id}>
      <Button
        style={{
          borderColor: interest.color,
          color: "black",
          textTransform: "capitalize",
          paddingBottom: 4,
          paddingTop: 4,
          margin: 2,
          borderRadius: 16,
        }}
        variant="outlined"
        onClick={handleClick}
      >
        <StyledBadge badgeContent={interest.users} max={100000}>
          <Box flexGrow={1} marginTop={0.5} marginBottom={0.5}>
            {interest.names[language]}
          </Box>
        </StyledBadge>
      </Button>
    </GridItem1>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    fontWeight: "lighter",
    fontSize: "0.7em",
    color: COLORS.badgeText,
    backgroundColor: COLORS.mainButton,
  },
}));

const SubStyledBadge = styled(StyledBadge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    top: -8,
  },
}));
