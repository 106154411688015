import { gql } from "graphql-request";
import { queryGraphQL } from "./backend";
import { Language } from "./languages";
import { User } from "./users";

export interface Post {
  id: number;
  connectorId: number;
  gifId: number;
  source: string;
  source_link?: string;
  title: string;
  description: string;
  content?: string;
  topic?: string;
  tags: Array<string>;
  interests: number;
  language: Language
  gif?: {
    url: string;
  }
}

export interface PostInterest {
  id: number;
  interest: {
    id?: number;
    name?: string;
  },
  subInterest: {
    id?: number;
    name?: string;
  },
  subSubInterest: {
    id?: number;
    name?: string;
  }
}

export const getPendingPosts = async (user: User, size: number, offset: number) => {
  const query = gql`
    query GetPendingPosts($sessionId: Int!, $sessionToken: String!, $size: Int!, $offset: Int!) {
      getPendingPosts(sessionId: $sessionId, sessionToken: $sessionToken, size: $size, offset: $offset) {
        id
        connectorId
        gifId
        source
        title
        description
        content
        topic
        tags
        interests
        language
      }
    }
  `;
  return await queryGraphQL<Array<Post>>(
    query,
    { sessionId: user.id, sessionToken: user.token, size, offset },
    "getPendingPosts"
  );
};

export const getLivePosts = async (user: User, size: number, offset: number) => {
  const query = gql`
    query GetLivePosts($sessionId: Int!, $sessionToken: String!, $size: Int!, $offset: Int!) {
      getLivePosts(sessionId: $sessionId, sessionToken: $sessionToken, size: $size, offset: $offset) {
        id
        connectorId
        gifId
        source
        title
        description
        content
        topic
        tags
        interests
        language,
      }
    }
  `;
  return await queryGraphQL<Array<Post>>(
    query,
    { sessionId: user.id, sessionToken: user.token, size, offset },
    "getLivePosts"
  );
};

export interface PostInput {
  id: number;
  connectorId: number;
  gifId?: number;
  source: string;
  title: string;
  description: string;
  content?: string;
  topic?: string;
  tags: Array<string>;
  language: Language;
}

export const upsertPost = async (
  user: User,
  input: PostInput
) => {
  const query = gql`
    mutation UpsertPost($sessionId: Int!, $sessionToken: String!, $input: PostInput!) {
      upsertPost(sessionId: $sessionId, sessionToken: $sessionToken, input: $input)
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    {
      sessionId: user.id, sessionToken: user.token, input,
    },
    "upsertPost"
  );
};

export const suggestTopic = async (
  user: User,
  title: string,
  description: string,
  content: string | undefined
) => {
  const graphqlQuery = gql`
    query SuggestTopic(
      $sessionId: Int!,
      $sessionToken: String!
      $title: String!
      $description: String!
      $content: String
    ) {
      suggestTopic(
        sessionId: $sessionId
        sessionToken: $sessionToken
        title: $title
        description: $description
        content: $content
      )
    }
  `;
  return await queryGraphQL<string>(
    graphqlQuery,
    { sessionId: user.id, sessionToken: user.token, title, description, content },
    "suggestTopic"
  );
};

export const getPostInterests = async (user: User, language: Language, postId: number) => {
  const query = gql`
    query GetPostInterests($sessionId: Int!, $sessionToken: String!, $language: Language!, $postId: Int!) {
      getPostInterests(sessionId: $sessionId, sessionToken: $sessionToken, language: $language, postId: $postId) {
        id
        interest {
          id
          name
        }
        subInterest {
          id
          name
        }
        subSubInterest {
          id
          name
        }
      }
    }
  `;
  return await queryGraphQL<Array<PostInterest>>(
    query,
    { sessionId: user.id, sessionToken: user.token, language, postId },
    "getPostInterests"
  );
};

export const insertPostInterest = async (
  user: User,
  postId: number,
  interestId: number,
  subInterestId: number,
  subSubInterestId: number
) => {
  const query = gql`
    mutation InsertPostInterest($sessionId: Int!, $sessionToken: String!, $postId: Int!, $interestId: Int!, $subInterestId: Int!, $subSubInterestId: Int!) {
      insertPostInterest(sessionId: $sessionId, sessionToken: $sessionToken, postId: $postId, interestId: $interestId, subInterestId: $subInterestId, subSubInterestId: $subSubInterestId)
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    {
      sessionId: user.id,
      sessionToken: user.token,
      postId,
      interestId,
      subInterestId,
      subSubInterestId
    },
    "insertPostInterest"
  );
};

export const removePostInterest = async (
  user: User,
  id: number
) => {
  const query = gql`
    mutation RemovePostInterest($sessionId: Int!, $sessionToken: String!, $id: Int!) {
      removePostInterest(sessionId: $sessionId, sessionToken: $sessionToken, id: $id)
    }
  `;
  return await queryGraphQL<boolean>(
    query,
    { sessionId: user.id, sessionToken: user.token, id },
    "removePostInterest"
  );
};