import { gql } from "@apollo/client";

export const GET_MAIN_INTERESTS = gql`
  query getInterests {
    interests(where: { parent_id: { _is_null: true }, active: { _eq: true } }) {
      name_en
      name_es
      name_fr
      name_ukr
      color
      active
      id
    }
  }
`;

export const GET_SUB_INTERESTS = gql`
  query getSubInterests {
    interests(where: { active: { _eq: true }, depth: { _eq: "2" } }) {
      name_en
      name_es
      name_fr
      name_ukr
      color
      active
      id
    }
  }
`;

export const GET_SUB_SUB_INTERESTS = gql`
  query getSubSubInterests {
    interests(where: { active: { _eq: true }, depth: { _eq: "3" } }) {
      name_en
      name_es
      name_fr
      name_ukr
      color
      active
      id
    }
  }
`;


export const GET_SINGLE_INTEREST = gql`
  query getSingleInterests($where: interests_bool_exp) {
    interests(where: $where) {
      id
      depth
      color
      parent_id
      name_en
      name_es
      name_fr
      name_ukr
      active
    }
  }
`;

export const UPDATE_INTEREST = gql`
  mutation updateInterest($id: String, $data: interests_set_input!) {
    update_interests(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;