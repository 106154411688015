import { gql } from "graphql-request";
import { Language } from "./languages";
import { Country } from "./countries";
import axios from "axios";
import {baseUrl} from "../config";
import {queryGraphQL} from "./backend";

export enum Privilege {
  Admin = "ADMIN",
}

export type User = {
  email: string;
  name?: string;
  picture?: string;
  id?: number;
  token?: string;
  privileges?: Array<Privilege>;
};

export const backOfficeLogin = (
  idToken: string,
  language: Language = Language.EN,
  country: Country = Country.GB
) => {
  const url = `${baseUrl}/auth/back-office-login`;


  return axios.post(url, {
    jwtToken: idToken,
  });
};

export type ActiveUserSession = {
  email: string;
  lastActiveSecs?: number;
};

export const getActiveUserSessions = async (user:User) => {
  const query = gql`
    query ActiveUserSessions($sessionId: Int!, $sessionToken: String!) {
      activeUserSessions(sessionId: $sessionId, sessionToken: $sessionToken)
    }
  `;
  return await queryGraphQL<Array<ActiveUserSession>>(
    query,
    { sessionId:user.id, sessionToken: user.token },
    "activeUserSessions"
  );
};
