import {Interest} from "../model/interests";

const formatInterests = (interest: any) => {
    return {
      ...interest,
      names: {
        en: interest?.name_en,
        es: interest?.name_es,
        fr: interest?.name_fr,
        ukr: interest?.name_ukr,
      } as Interest["names"],
    };
};

export { formatInterests };
