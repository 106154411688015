import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { act, ChangeEvent, useCallback, useEffect, useState } from "react";
import { CancelButton, MainButton, TextButton } from "../components/buttons";
import { FormGrid, GridItem1 } from "../components/grids";
import { SelectMenu } from "../components/selectmenu";
import {
  createInterest,
  createSubInterest,
  createSubSubInterest,
  getInterestUsers,
  Interest,
  InterestColors,
  updateInterest,
  updateSubInterest,
  updateSubSubInterest,
} from "../model/interests";
import { User } from "../model/users";
import { useAppDispatch, useAppSelector } from "../store";
import { editInterest, InterestType, setLastError, setView, Views } from "../store/session";
import { SUB_COLORS } from "../theme";
import { ChildInterestsPanel } from "./interests";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_MAIN_INTERESTS, GET_SINGLE_INTEREST, UPDATE_INTEREST} from "../Queries/InterestsQueries";
import {formatInterests} from "../utils";

const PAGE_SIZE = 50;

interface InterestPayload {
  active: boolean;
  color: string;
  depth: string; // Assuming depth is represented as a string
  name_en: string;
  name_es: string;
  name_fr: string;
  name_ukr: string;
  parent_id: string | null; // Assuming parent_id is represented as a string
  id?: string;
  is_deleted?: boolean;
}

export const EditInterest = () => {
  const user = useAppSelector((state) => state.session.user);
  const interestId = useAppSelector((state) => state.session.editInterest?.id);
  const interestType = useAppSelector((state) => state.session.editInterest?.type);
  const parentId = useAppSelector((state) => state.session.editInterest?.parentId);
  const dispatch = useAppDispatch();
  const [nameEn, setNameEn] = useState("");
  const [nameEs, setNameEs] = useState("");
  const [nameFr, setNameFr] = useState("");
  const [nameUkr, setNameUkr] = useState("");
  const [active, setActive] = useState(false);
  const [color, setColor] = useState(SUB_COLORS.ViridianGreen);
  const [depth, setDepth] = useState<string | undefined>(undefined);
  const [childInterests, setChildInterests] = useState<Interest[]>([]);
  const [from] = useState(0);
  const [interestUsers, setInterestUsers] = useState<User[]>([]);


  const [getInterest] = useLazyQuery(GET_SINGLE_INTEREST);
  const [updateInterest, {loading: updateLoading}] = useMutation(UPDATE_INTEREST, {
    refetchQueries: [{query: GET_MAIN_INTERESTS}]
  });

  let childInterestType: InterestType | undefined;
  switch (interestType) {
    case 'main':
      childInterestType = 'sub';
      break;
    case 'sub':
      childInterestType = 'subsub';
      break;
  }

  const loadInterest = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());
      
      const set = (interests: Interest) => {
        if (interests) {
          const interest = formatInterests(interests);
          if (interest) {
            setNameEn(interest.names.en);
            setNameEs(interest.names.es);
            setNameFr(interest.names.fr);
            setNameUkr(interest.names.ukr);
            setDepth(interest.depth);
            setColor(interest.color);
            setActive(interest.active);
          }
        }
      };

      const err = (error: string | undefined) => {
        if (error) {
          dispatch(setLastError(error));
        }
      }

      const where: any = {};
      where.id = {
        _eq: interestId
      }
      switch (interestType) {
        case "main":
          where.parent_id = { _is_null: true };
          where.depth = { _eq: "1" };
          break;
        case "sub":
          where.parent_id = { _is_null: false };
          where.depth = { _eq: "2" };
          break;
        case "subsub":
          where.parent_id = { _is_null: false };
          where.depth = { _eq: "3" };
          break;
      }

      try {
        const response = await getInterest({ variables: { where } });
        if (response.data) {
          const data = response.data.interests[0];
          set(data);
        }
        err(JSON.stringify(response.error));
      } catch (error: any) {
        err(error);
      }

    }
  }, [dispatch, interestId, interestType, user]);

  const loadInterestUsers = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());
      const { data, error } = await getInterestUsers(
        user,
        interestId,
        from,
        PAGE_SIZE
      );
      if (data) {
        setInterestUsers(data);
      }
      if (error) {
        dispatch(setLastError(error));
      }
    }
  }, [dispatch, from, interestId, user]);

  const loadChildInterests = useCallback(async () => {
    if (user && interestId) {
      dispatch(setLastError());
      let where: any = {};
      switch (interestType) {
        case "main":
          where = {
            depth: {
              _eq: "2",
            },
            parent_id: {
              _eq: interestId,
            },
          };
          break;
        case "sub":
          where = {
            depth: {
              _eq: "3",
            },
            parent_id: {
              _eq: interestId,
            },
          };
          break;
      }

      try {
        const response = await getInterest({
          variables: { where },
        });
        if (response.data) {
          const data = response.data.interests?.map(formatInterests);
          setChildInterests(data);
        }
      } catch (error: any) {
        if (error) {
          dispatch(setLastError(error));
        }
      }
    }
  }, [dispatch, getInterest, interestId, interestType, user]);

  useEffect(() => {
    const load = async () => {
      await loadInterest();
      await loadChildInterests();
      await loadInterestUsers();
    };
    load().catch((error) => dispatch(setLastError(error)));
  }, [dispatch, loadChildInterests, loadInterest, loadInterestUsers]);

  const handleNameChangeEn = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameEn(event.currentTarget.value);
    },
    [setNameEn]
  );

  const handleNameChangeEs = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameEs(event.currentTarget.value);
    },
    [setNameEs]
  );

  const handleNameChangeFr = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameFr(event.currentTarget.value);
    },
    [setNameFr]
  );

  const handleNameChangeUkr = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNameUkr(event.currentTarget.value);
    },
    [setNameUkr]
  );

  const handleChangeColor = useCallback(
    (color: string) => {
      setColor(color);
    },
    [setColor]
  );

  const handleChangeActive = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setActive(event.target.checked);
    },
    [setActive]
  );

  const handleCreateSubInterest = useCallback(() => {
    switch (depth) {
      case "1":
        dispatch(editInterest({parentId: interestId, type: 'sub'}));
        break;
      case "2":
        dispatch(editInterest({parentId: interestId, type: 'subsub'}));
        break;
    }
  }, [depth, dispatch, interestId]);

  const handleCancel = useCallback(() => {
    dispatch(setView(Views.Interests));
  }, [dispatch]);
  
  const handleSave = useCallback(async () => {
    if (user) {
      let names = {
        en: nameEn,
        es: nameEs,
        fr: nameFr,
        ukr: nameUkr,
      };

      let payLoad: InterestPayload = {} as InterestPayload;

      if (interestId) {
        switch (interestType) {
          case "main":
            payLoad = {
              active: active,
              color: color,
              depth: depth ? depth : "0",
              id: interestId ? `${interestId}` : undefined,
              name_en: nameEn,
              name_es: nameEs,
              name_fr: nameFr,
              name_ukr: nameUkr,
              parent_id: null,
            };

            break;
          case "sub":
            if (parentId) {
              payLoad = {
                active: active,
                color: color,
                depth: depth ? depth : "0",
                id: interestId ? `${interestId}` : undefined,
                name_en: nameEn,
                name_es: nameEs,
                name_fr: nameFr,
                name_ukr: nameUkr,
                parent_id: parentId ? `${parentId}` : null,
              };
            }
            break;
          case "subsub":
            if (parentId) {
              payLoad = {
                active: active,
                color: color,
                depth: depth ? depth : "0",
                id: interestId ? `${interestId}` : undefined,
                name_en: nameEn,
                name_es: nameEs,
                name_fr: nameFr,
                name_ukr: nameUkr,
                parent_id: parentId ? `${parentId}` : null,
              };
            }
            break;
        }

        await updateInterest({
          variables: {
            id: interestId,
            data: payLoad,
          },
        });
      } else {
        switch (interestType) {
          case "main":
            await createInterest(user, color, names, active);
            break;
          case "sub":
            if (parentId) {
              await createSubInterest(user, parentId, names, active);
            }
            break;
          case "subsub":
            if (parentId) {
              await createSubSubInterest(user, parentId, names, active);
            }
            break;
        }
      }
      handleCancel();
    }
  }, [
    user,
    nameEn,
    nameEs,
    nameFr,
    nameUkr,
    interestId,
    handleCancel,
    interestType,
    updateInterest,
    active,
    color,
    depth,
    parentId,
  ]);

  let editVerb = interestId ? "Edit" : "Add";
  let childrenTitle;
  switch (depth) {
    case "1":
      childrenTitle = "Sub-Interest";
      break;
    case "2":
      childrenTitle = "Sub-Sub-Interest";
      break;
    default:
      childrenTitle = "";
      break;
  }

  return (
    <Stack spacing={1}>
      <Paper>
        <Stack spacing={1} padding={2}>
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} spacing={1}>
              <Typography variant="h4">{editVerb} /</Typography>
              <Typography variant="h4">{nameEn}</Typography>
            </Stack>
            {interestId && childrenTitle && (
              <TextButton onClick={handleCreateSubInterest}>
                Click here to create a {childrenTitle}
              </TextButton>
            )}
          </Stack>
          <br />
          <FormGrid>
            <GridItem1>
              <TextField
                fullWidth
                id="name-en"
                label="English"
                value={nameEn}
                onChange={handleNameChangeEn}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-es"
                label="Spanish"
                value={nameEs}
                onChange={handleNameChangeEs}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-fr"
                label="French"
                value={nameFr}
                onChange={handleNameChangeFr}
              />
            </GridItem1>
            <GridItem1>
              <TextField
                fullWidth
                id="name-ukr"
                label="Ukrainian"
                value={nameUkr}
                onChange={handleNameChangeUkr}
              />
            </GridItem1>
          </FormGrid>
          <FormGrid>
            {interestType === 'main' && (
              <GridItem1>
                <Stack direction={"row"} spacing={1}>
                  <SelectMenu
                    fullWidth
                    id="color"
                    value={color}
                    label="Color"
                    onChange={handleChangeColor}
                  >
                    {Object.keys(SUB_COLORS).map((color) => (
                      <MenuItem
                        style={{
                          backgroundColor: SUB_COLORS[color as InterestColors],
                        }}
                        key={color}
                        value={SUB_COLORS[color as InterestColors]}
                      >
                        {color}
                      </MenuItem>
                    ))}
                  </SelectMenu>
                  <Box
                    alignSelf={"center"}
                    bgcolor={color}
                    width={50}
                    height={40}
                  />
                </Stack>
              </GridItem1>
            )}
            <GridItem1>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Switch checked={active} onChange={handleChangeActive} />
                  }
                  label="Active"
                />
              </FormGroup>
            </GridItem1>
          </FormGrid>
          <Stack spacing={1} direction="row-reverse">
            <MainButton variant="contained" onClick={handleSave}>
              {interestId ? "Save" : "Create"}
            </MainButton>
            <CancelButton variant="outlined" onClick={handleCancel}>
              Cancel
            </CancelButton>
          </Stack>
        </Stack>
      </Paper>
      {childInterestType && childInterests && childInterests.length > 0 && (
        <Paper>
          <ChildInterestsPanel
            title={`${childrenTitle}s`}
            interests={childInterests}
            type={childInterestType}
            language={"en"}
          />
        </Paper>
      )}
      <InterestUsers interestUsers={interestUsers} />
    </Stack>
  );
};

const InterestUsers = ({ interestUsers }: { interestUsers: User[] }) => {
  return (
    <Paper>
      <Typography paddingTop={2} paddingLeft={2} variant="h5" paddingRight={2}>
        Users
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">Username</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold">Email</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interestUsers.map((interestUser) => (
              <TableRow
                key={interestUser.email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {interestUser.name}
                </TableCell>
                <TableCell>{interestUser.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
